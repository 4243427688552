import React, { useState } from 'react';
import './ContactUs.css';

function SuccessScreen({ onOk }) {
  return (
    <div className="success-screen">
      <h2>Form submitted successfully!</h2>
      <button onClick={onOk}>OK</button>
    </div>
  );
}

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isFormValid = () => {
    // Add validation logic here
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      setSubmitError('All fields are required.Please fill all fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return; // Stop the form submission if validation fails
    }
    
    const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSeVDNm0UNJYreAHemURgHDmy0_0euA4Lt-hY2S1PMIhzsgXmA/formResponse';
    
    const submitData = new FormData();
    submitData.append('entry.2042123984', formData.name);
    submitData.append('entry.392015165', formData.email);
    submitData.append('entry.2062776782', formData.subject);
    submitData.append('entry.1339814012', formData.message);
    
    try {
      await fetch(googleFormUrl, {
        method: 'POST',
        body: submitData,
        mode: 'no-cors'
      });
      setIsSubmitted(true);
      setSubmitError('');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error("Form submission error:", error);
      setIsSubmitted(false);
      setSubmitError('An error occurred while submitting the form.');
    }
  };

  const handleOkClick = () => {
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return <SuccessScreen onOk={handleOkClick} />;
  }

  return (
    <div className="contact-form">
      <h2>Contact Aiden</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Send Message</button>
      </form>
      {submitError && <div className="error-message">{submitError}</div>}
    </div>
  );
}

export default ContactUs;
